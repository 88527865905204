import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

import "dayjs/locale/en";
import "dayjs/locale/hu";
import "dayjs/locale/ro";

dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

dayjs.updateLocale('en', {
  formats: {
    L: 'YYYY-MM-DD',
  }
});

dayjs.locale("en");

const date = {
  getMonths() {
    let months = [];
    for(let i=0;i<12;i++){
      let date = dayjs().month(i);
      months.push(date.format("MMMM"));
    }
    return months;
  },
  setLocale(locale) {
    dayjs.locale(locale);
  },
  formatFullName(val) {
    const date = dayjs(val);
    return date.format("LL");
  },
  format(val, time = true, relative = true) {
    const date = dayjs(val);
    if (!relative || Math.abs(date.unix() - dayjs().unix()) > 86400) {
      if (time) {
        return date.format("L HH:mm");
      }
      return date.format("L");
    }

    if (Math.abs(date.unix() - dayjs().unix()) > 60) {
      if (time) {
        return dayjs().to(date) + " " + date.format("HH:mm");
      }
    }

    if (Math.abs(date.unix() - dayjs().unix()) > 60) {
      if (time) {
        return "Today " + date.format("HH:mm");
      }
    }
    return dayjs().to(date);
  },
};

export default date;
