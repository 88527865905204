<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="className">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`modal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <keep-alive>
            <component
              v-if="currentTabComponent"
              :is="currentTabComponent"
              v-bind:data="data"
            ></component>
            <slot v-else></slot>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import externalComponent from "@/modules/externalComponent";
import store from "@/store";

const bootstrap = require("bootstrap");

export default {
  name: "Modal",
  props: {
    component: String,
    title: String,
    appId: Number,
    data: Object,
    className: String,
  },
  data() {
    return {
      myModal: undefined,
      currentTabComponent: undefined,
      modalData: undefined,
    };
  },
  watch: {
    component() {
      this.load();
    },
    data: {
      deep: true,
      handler() {
        this.modalData = this.data;
      },
    },
  },
  mounted() {
    if (this.component !== undefined && this.component !== null) {
      this.load();
    }
  },
  methods: {
    load() {
      const _this = this;
      this.modalData = this.data;
      externalComponent(
        `/${this.appId}/modals/${this.component}.umd.min.js`
      ).then((MyComponent) => {
        _this.currentTabComponent = MyComponent;
        _this.showModal();
      });
    },
    hideModal(){
      this.myModal.hide();
    },
    showModal() {
      if (this.myModal === undefined) {
        this.$refs.modal.addEventListener("hidden.bs.modal", function () {
          store.commit("showModal", undefined);
        });
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
  },
};
</script>
