<template>
  <div class="home">
    <div>
      <!-- CONTENT AREA -->
      <div class="row">
        <div class="col-12">
          <div class="gradient-box w-100 text-center">
            <h3 class="bold-24">{{ $t("home.onlineSalesChannel") }}</h3>
          </div>
        </div>
      </div>
      <!-- TILES SECTION -->
      <div class="row">
        <div class="col-12" v-if="payments.length > 0">
          <div class="white-box">
            <div class="white-box-top"></div>
            <div class="px-3 py-2 row">
              <div class="col-12">
                <h2>{{ $t("home.highRateOfFailedPaymentsInSomeCountries") }}</h2>
                <p>
                  {{ $t("home.weHaveDetectedAnUnusuallyHighRateOfFailedPaymentsI") }}
                </p>
                <p>
                  {{ $t("home.considerAddingLocalPaymentMethodsToCaterToRegional") }}
                </p>
              </div>
              <div
                class="row mt-3 col-6"
                v-for="payment in payments"
                :key="payment.code"
              >
                <div class="col-4 bold-19">{{ payment.name }}</div>
                <div class="col-4 bold-19 text-danger">
                  {{ $t(`home.failed2`, {round: Math.round(payment.percent * 100)}) }}
                </div>
                <div class="col-4 bold-19 text-danger">
                  {{ $t(`home.unsuccessfulPayments`, {round: Math.round(payment.failed * 0.94)}) }}
                </div>
                <div class="col-4 align-self-center">
                  {{ $t(`home.mostPopularPaymentMethodsIn`, {name: payment.name}) }}
                </div>
                <div class="col-8 mt-3">
                  <div
                    v-for="method in payment.methods"
                    :key="method.name"
                    class="row mb-2 ms-1 ps-3 pe-4"
                    :style="`background: linear-gradient(
                        90deg,
                        rgba(180, 96, 220, 0.1) 0%,
                        rgba(180, 96, 220, 0.1) ${method.popularity * 20}%,
                        rgba(180, 96, 220, 0) ${method.popularity * 20}%,
                        rgba(180, 96, 220, 0) 100%
                      );`"
                  >
                    <div class="col-8 pt-1 bold-14 text-gradient">
                      {{ method.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="row">
            <!-- LEFT 1 -->
            <div class="col-12">
              <div class="white-box text-end">
                <div class="white-box-top"></div>
                <div class="line-cart">
                  <LineChart
                    :chartData="salesByDays"
                    :options="optionsNoText"
                    :key="key"
                  />
                </div>
                <div class="text-area right">
                  <h2 class="bold-19 text-default mb-2">{{ $t("home.orders") }}</h2>
                  <h2 class="bold-24 text-default">
                    {{ total?.success?.orders }}
                  </h2>
                </div>
              </div>
            </div>
            <!-- LEFT 2 -->
            <div class="col-6">
              <div class="white-box text-center">
                <div class="white-box-top"></div>
                <div class="text-area">
                  <h2 class="bold-19 text-danger mb-2">{{ $t("home.returns") }}</h2>
                  <h2 class="bold-24 text-danger">
                    {{ total?.success?.return }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="white-box text-center">
                <div class="white-box-top"></div>
                <div class="text-area">
                  <h2 class="bold-19 text-primary mb-2">{{ $t("home.changes") }}</h2>
                  <h2 class="bold-24 text-primary">
                    {{ total?.success?.changes }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <!-- CENTER 1 -->
          <div class="col-12">
            <div class="white-box-big text-center">
              <div class="white-box-top"></div>
              <div class="text-area">
                <div class="line-cart">
                  <LineChart
                    :chartData="revenueByDays"
                    :options="optionsNoText"
                    :key="key"
                  />
                </div>
                <h2 class="bold-19 text-gradient mb-2 mt-3">{{ $t("home.soldProducts") }}</h2>
                <div class="bold-24 text-gradient">
                  <EditOnClick
                    :model-value="total?.success?.revenue"
                    type="price"
                    :editable="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="row">
            <!-- RIGHT 1 -->
            <div class="col-12">
              <div class="white-box text-end">
                <div class="white-box-top"></div>
                <div class="line-cart line-cart-10">
                  <LineChart
                    :chartData="avgByDays"
                    :options="optionsNoText"
                    :key="key"
                  />
                </div>
                <div class="text-area right">
                  <h2 class="bold-19 text-green mb-2">{{ $t("home.avgBasket") }}</h2>
                  <h2 class="bold-19 text-green">
                    <EditOnClick
                      :model-value="total?.success?.avg"
                      type="price"
                      :padding="false"
                      :editable="false"
                    />
                  </h2>
                </div>
              </div>
            </div>
            <!-- RIGHT 2 -->
            <div class="col-12">
              <div class="white-box">
                <div class="white-box-top"></div>
                <div class="text-area">
                  <div
                    class="row mb-2 ms-1 ps-3 pe-4"
                    :style="`background: linear-gradient(
                        90deg,
                        rgba(180, 96, 220, 0.1) 0%,
                        rgba(180, 96, 220, 0.1) ${percent(
                          total?.success?.orders / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) ${percent(
                          total?.success?.orders / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) 100%
                      );`"
                  >
                    <div class="col-8 pt-1 bold-14 text-gradient">
                      {{ $t("home.successful") }}
                    </div>
                    <div class="col bold-19 text-gradient text-end">
                      {{ percent(total?.success?.orders / totalCount) }}%
                    </div>
                  </div>
                  <div
                    class="row mb-2 ms-1 ps-3 pe-4"
                    :style="`background: linear-gradient(
                        90deg,
                        rgba(180, 96, 220, 0.1) 0%,
                        rgba(180, 96, 220, 0.1) ${percent(
                          total?.failed?.count / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) ${percent(
                          total?.failed?.count / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) 100%
                      );`"
                  >
                    <div class="col-8 pt-1 bold-14 text-gradient">{{ $t("home.failed") }}</div>
                    <div class="col bold-19 text-gradient text-end">
                      {{ percent(total?.failed?.count / totalCount) }}%
                    </div>
                  </div>
                  <div
                    class="row mb-2 ms-1 ps-3 pe-4"
                    :style="`background: linear-gradient(
                        90deg,
                        rgba(180, 96, 220, 0.1) 0%,
                        rgba(180, 96, 220, 0.1) ${percent(
                          total?.not_finished?.count / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) ${percent(
                          total?.not_finished?.count / totalCount
                        )}%,
                        rgba(180, 96, 220, 0) 100%
                      );`"
                  >
                    <div class="col-8 pt-1 bold-14 text-gradient">
                      {{ $t("home.notFinished") }}
                    </div>
                    <div class="col bold-19 text-gradient text-end">
                      {{ percent(total?.not_finished?.count / totalCount) }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-for="panel in panels" :key="panel.name" class="col-6">
        <div class="white-box">
          <div class="white-box-top"></div>
          <div class="px-3 py-2">
            <keep-alive>
              <component
                :is="panel.component"
                v-model="panel.data"
                :appId="panel.id"
              ></component>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import externalComponent from "../../modules/externalComponent";
import http from "../../modules/http";
import EditOnClick from "../../components/inputs/EditOnClick";
import { LineChart } from "vue-chart-3";

export default {
  name: "Home",
  components: { EditOnClick, LineChart },
  data() {
    return {
      store: useStore(),
      panels: [],
      appData: {},
      total: {},
      payments: [],
      switch2: "category",
      key: 0,
      firstLoad: true,
      optionsNoText: {
        padding: 0,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      },
      options: {
        padding: 0,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      },
      revenueByDays: {
        labels: [],
        datasets: [
          {
            label: "Sold Products",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      salesByDays: {
        labels: [],
        datasets: [
          {
            label: "Sales",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      avgByDays: {
        labels: [],
        datasets: [
          {
            label: "Sales",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
    };
  },
  computed: {
    date() {
      return this.store.state.topBar.date;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    totalCount() {
      return (
        this.total?.success?.orders +
        this.total?.failed?.count +
        this.total?.not_finished?.count
      );
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    warehouse() {
      this.loadData();
    },
  },
  methods: {
    percent(val) {
      return Math.round(val * 10000) / 100;
    },
    loadData() {
      http
        .fetch(
          `/home?1${this.warehouse ? "&warehouse=" + this.warehouse : ""}${
            (this.date[0]
              ? "&from=" + this.date[0].toISOString().split("T")[0]
              : "") +
            (this.date[1]
              ? "&to=" + this.date[1].toISOString().split("T")[0]
              : "")
          }`
        )
        .then((data) => {
          this.total = data.total;
          this.appData = data.apps;
          this.payments = data.payments;

          let labels = [];
          let revenue = [];
          let sales = [];
          let avg = [];
          for (const date in data.total.daily) {
            labels.push(date);
            revenue.push(Number.parseFloat(data.total.daily[date].total));
            sales.push(Number.parseFloat(data.total.daily[date].count));
            avg.push(Number.parseFloat(data.total.daily[date].avg));
          }
          this.revenueByDays.labels = labels;
          this.revenueByDays.datasets[0].data = revenue;
          this.salesByDays.labels = labels;
          this.salesByDays.datasets[0].data = sales;
          this.avgByDays.labels = labels;
          this.avgByDays.datasets[0].data = avg;

          this.loadPanels();
          if (this.firstLoad) {
            this.firstLoad = false;
          }
        });
    },
    loadPanels() {
      let _this = this;
      const apps = this.store.state.apps;
      for (const key in apps) {
        if (apps[key].homeComponents !== undefined) {
          for (const compKey in apps[key].homeComponents) {
            apps[key].homeComponents[compKey].app = apps[key].name;
            apps[key].homeComponents[compKey].data = this.appData[apps[key].id];

            if (this.firstLoad) {
              externalComponent(
                `/${apps[key].name}/home/${apps[key].homeComponents[compKey].name}.umd.min.js`
              ).then((MyComponent) => {
                apps[key].homeComponents[compKey].component = MyComponent;
                _this.panels.push(apps[key].homeComponents[compKey]);
              });
            }
          }
        }
      }
    },
  },
};
</script>
