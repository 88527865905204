import ws from "./ws";
import store from "../store/index";

const tags = {
  storeInit() {
    ws.init();
    ws.subscribe("add", "tag", (e) => {
      store.commit("addTag", e.object);
    });
  },
};

export default tags;
