import store from "../store/index";
import router from "../router/index";
import tenant from "./tenant";

const http = {
  fetch(
    url,
    data = null,
    successBadge = false,
    method = null,
    loadingMessage = null
  ) {
    const key = new Date().getUTCMilliseconds();
    if (loadingMessage !== null) {
      store.commit("setApiLoading", {
        message: loadingMessage,
        loading: true,
        key: key,
      });
    }
    return new Promise((resolve, reject) => {
      let obj = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + store.state.token,
          Language: store.state.user?.language_code,
        },
      };
      if (data !== null) {
        let body = new FormData();

        for (const key in data) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            if (key === "tags" && data[key]?.length === 0) {
              body.append(`${key}`, null);
            }
            for (const key2 in data[key]) {
              if (typeof data[key][key2] === "object") {
                for (const key3 in data[key][key2]) {
                  if (typeof data[key][key2][key3] === "object") {
                    for (const key4 in data[key][key2][key3]) {
                      body.append(
                        `${key}[${key2}][${key3}][${key4}]`,
                        data[key][key2][key3][key4]
                      );
                    }
                  } else {
                    body.append(
                      `${key}[${key2}][${key3}]`,
                      data[key][key2][key3]
                    );
                  }
                }
              } else {
                body.append(`${key}[${key2}]`, data[key][key2]);
              }
            }
          } else {
            body.append(key, data[key]);
          }
        }

        obj.method = "POST";
        if (method !== null) {
          body.append("_method", method);
        }
        obj.body = body;
      }
      fetch(tenant.getApiBaseUrl() + "" + url, obj)
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((data) => {
          if (successBadge) {
            store.commit("setApiSuccess", data);
          }
          if (loadingMessage !== null) {
            store.commit("setApiLoading", { loading: false, key: key });
          }
          resolve(data);
        })
        .catch((err) => {
          err.json.then((data) => {
            if (data?.message === "Unauthenticated.") {
              store.commit("logout");
              router.push("/login");
            } else if (data?.message === "Forbidden.") {
              store.commit("setApiError", data);
            } else if (successBadge) {
              store.commit("setApiError", data);
            } else if (data?.message?.length > 1) {
              store.commit("setApiError", data);
            }
            if (loadingMessage !== null) {
              store.commit("setApiLoading", { loading: false, key: key });
            }
            reject(data);
          });
        });
    });
  },
};

export default http;
