import { createApp } from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Sentry from "@sentry/vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { registerScrollSpy } from "vue3-scroll-spy";
import "bootstrap/dist/css/bootstrap.css";
import { Skeletor } from "vue-skeletor";

import { Chart, registerables } from "chart.js";
import http from "./modules/http";

Chart.register(...registerables);
import * as Vue from "vue/dist/vue.esm-bundler";
import externalModule from "./modules/externalModule";
import Apps from "@/modules/apps/apps";

window.Vue = Vue;
window.apps = Apps;

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .component(Skeletor.name, Skeletor)
  .component("v-select", vSelect);

registerScrollSpy(app);

Sentry.init({
  app,
  dsn: "https://72f1aab94f314e4995eec8e77addf45b@sentry.weborigo.eu/5",
});

app.mount("#app");

http.fetch("/apps").then((appsData) => {
  const defaultApps = appsData.defaultApps;

  const installedApps = appsData.installedApps;

  (async () => {
    for (const installedApp of installedApps) {
      externalModule(
        `/${installedApp.class_name}/index.js?i=${new Date().toISOString()}`
      ).then((app) => {
        app.appComponents = app.init(
          installedApp.sub_id,
          installedApp.appOptions
        );
        app.appComponents.name = installedApp.class_name;
        app.appComponents.id = installedApp.sub_id;
        store.commit("addApp", app.appComponents);
      });
    }
  })();
  (async () => {
    for (const defaultApp of defaultApps) {
      externalModule(
        `/${defaultApp.class_name}/index.js?i=${new Date().toISOString()}`
      ).then((app) => {
        app.appComponents = app.init(defaultApp.sub_id, defaultApp.appOptions);
        app.appComponents.name = defaultApp.class_name;
        app.appComponents.id = defaultApp.sub_id;
        store.commit("addDefaultApp", app.appComponents);
      });
    }
  })();
  setTimeout(() => {
    router.afterEachMethod(router.currentRoute.value);
  }, 400);
});
