import PusherJS from "pusher-js";
import store from "../store";
const ws = {
  wsClient: null,

  init(token = null) {
    if (token === null) {
      token = store.state.token;
    }
    if (this.wsClient === null) {
      this.wsClient = new PusherJS(process.env.VUE_APP_PUSHER_KEY, {
        wsHost: process.env.VUE_APP_PUSHER_DOMAIN,
        wsPort: process.env.VUE_APP_PUSHER_PORT,
        httpHost: process.env.VUE_APP_PUSHER_HTTP_HOST,
        authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
        forceTLS: process.env.VUE_APP_PUSHER_FORCETLS === "true",
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        encrypted: true,
        disableStats: true,
        enabledTransports: ["ws", "wss"],
      });
    } else {
      this.wsClient.config.auth.headers.Authorization = `Bearer ${token}`;
    }
  },

  subscribe(channel, event, resolve) {
    this.wsClient.subscribe(`private-${channel}`).bind(event, (e) => {
      resolve(e);
    });
  },
};

export default ws;
