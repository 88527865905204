export class Page {
  constructor(name) {
    this.name = name;
    this.events = {};
  }

  open() {
    this.callEvent("open");
  }

  close() {
    this.callEvent("close");
  }

  addListOption(text, icon, onClick, always = false) {
    return this.callEvent("addListOption", text, icon, onClick, always);
  }

  callEvent(event, ...data) {
    if (this.events[event] === undefined) {
      return;
    }
    let method = this.events[event];
    return method(...data);
  }

  subscribe(event, method) {
    this.events[event] = method;
  }
}
